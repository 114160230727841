import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { object, ref, string } from "yup";

const Main = () => {
  const [isPassword, setisPassword] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);

  const LoginSchema = object().shape({
    firstname: string()
      .required("Please enter first name.")
      .trim("Please enter first name."),

    lastname: string()
      .required("Please enter last name.")
      .trim("Please enter last name."),

    mobile: string()
      .required("Please enter contact number.")
      .trim("Please enter contact number."),

    email: string()
      .required("Please enter email.")
      .trim("Please enter email.")
      .email("Invalid Email Format. Please try again."),

    password: string()
      .required("Please enter password.")
      .trim("Please enter password."),

    confirm_password: string()
      .required("Please enter confirm password.")
      .trim("Please enter confirm password.")
      .oneOf(
        [ref("password")],
        "Password and confirm password does not match."
      ),
  });

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: LoginSchema,

      initialValues: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        password: "",
        confirm_password: "",
        postalcode: "",
        city: "",
        state: "",
        country: "",
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("called submit ", values);
      },

      onReset: () => {},
    });

  return (
    <div className="auto-container" style={{ marginTop: "230px" }}>
      <div
        // className="c-page-form-box"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="loc-block col-lg-6 col-md-12 col-sm-12">
          <div className="title-box centered" style={{ marginBottom: "40px" }}>
            <h2>Register</h2>
          </div>
          <div className="default-form reservation-form">
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                handleSubmit();
              }}
            >
              <div className=" row clearfix">
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First Name*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstname}
                      style={{
                        borderColor:
                          touched?.firstname && errors?.firstname && "red",
                      }}
                    />
                  </div>
                  {touched?.firstname && errors?.firstname && (
                    <p className="error-message">{errors.firstname}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last Name*"
                      // required=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastname}
                      style={{
                        borderColor:
                          touched?.lastname && errors?.lastname && "red",
                      }}
                    />
                  </div>
                  {touched?.lastname && errors?.lastname && (
                    <p className="error-message">{errors.lastname}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      style={{
                        borderColor: touched?.email && errors?.email && "red",
                      }}
                      // required=""
                    />
                  </div>
                  {touched?.email && errors?.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="mobile"
                      placeholder="Phone*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      style={{
                        borderColor: touched?.mobile && errors?.mobile && "red",
                      }}
                      // required=""
                    />
                  </div>
                  {touched?.mobile && errors?.mobile && (
                    <p className="error-message">{errors.mobile}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="">
                    <input
                      type={isPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      style={{
                        borderColor:
                          touched?.password && errors?.password && "red",
                      }}
                    />
                    <span
                      className={`arrow-icon far  ${
                        isPassword ? "fa-eye" : "fa-eye-slash"
                      } `}
                      style={{
                        cursor: "pointer",
                        zIndex: "50",
                        position: "absolute",
                        top: "18px",
                        right: "28px",
                      }}
                      onClick={() => {
                        setisPassword(!isPassword);
                      }}
                    ></span>
                  </div>
                  {touched?.password && errors?.password && (
                    <p className="error-message">{errors.password}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="">
                    <input
                      type={isConfirmPassword ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Confirm Password*"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirm_password}
                      style={{
                        borderColor:
                          touched?.confirm_password &&
                          errors?.confirm_password &&
                          "red",
                      }}
                    />
                    <span
                      className={`arrow-icon far  ${
                        isConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      } `}
                      style={{
                        cursor: "pointer",
                        zIndex: "50",
                        position: "absolute",
                        top: "18px",
                        right: "28px",
                      }}
                      onClick={() => {
                        setisConfirmPassword(!isConfirmPassword);
                      }}
                    ></span>
                  </div>
                  {touched?.confirm_password && errors?.confirm_password && (
                    <p className="error-message">{errors.confirm_password}</p>
                  )}
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select placeholder="Country">
                      <option value="">Country</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select placeholder="State">
                      <option value="">State</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <select placeholder="City">
                      <option value="">City</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      type="number"
                      name="postalcode"
                      placeholder="Zip "
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postalcode}
                      style={{
                        borderColor:
                          touched?.postalcode && errors?.postalcode && "red",
                      }}
                    />
                  </div>
                  {touched?.postalcode && errors?.postalcode && (
                    <p className="error-message">{errors.postalcode}</p>
                  )}
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 ">
                  <div className="field-inner">
                    <textarea
                      name="address"
                      placeholder="Address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Register</span>
                      <span className="text-two">Register</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
