import React from "react";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Specialmenu from "../Allmenus/Menmenu/Specialmenu";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/cart.jpg";
import Cart from "../Allmenus/Menmenu/Cart";

function Main() {
  return (
    <>
      <Bredcrumb subtitle="Cart" title="DELICIOUS & AMAZING" Img={Img} />
      {/* <Specialmenu /> */}
      <Cart />
      {/* <Reservation /> */}
    </>
  );
}

export default Main;
