import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const login = (data) => {
  let headers = {
    Accept: "*/*",
  };
  return new Promise(function (resolve, reject) {
    axios
      .post("/user_login", { ...data }, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createAccount = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/register-customer", { ...data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const verifyLinkAPI = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/verify-link", { email_token: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const register = (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/register", data, { headers: config })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const lostPassword = (email, token) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/lost-password", { username: email, token: token })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const resetPassword = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/reset-password", { ...data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const handleUsername = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/reset-password-detail", { ...data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const logout = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/logout", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const postCookiesData = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/cookie-data", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const cookiesData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get("/cookie-data", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  } else {
    reject(error);
  }
}

export {
  login,
  register,
  handleUsername,
  logout,
  lostPassword,
  createAccount,
  postCookiesData,
  verifyLinkAPI,
  cookiesData,
  resetPassword,
};
