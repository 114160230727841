import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { login } from "../../services/AuthService";

const Main = () => {
  const [isLoading, setisLoading] = useState(false);
  const [isPassword, setisPassword] = useState(false);

  const navigate = useNavigate();

  const LoginSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .trim("Please enter email.")
      .email("Invalid Email Format. Please try again."),

    password: string()
      .required("Please enter password.")
      .trim("Please enter password."),
  });

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: LoginSchema,

      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: async (values, { resetForm }) => {
        setisLoading(true);

        await login(values)
          .then((response) => {
            setisLoading(false);
            localStorage.setItem("is_authenticated", 1);
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("userId", response?.data?.data?.userId);

            toast("Customer Login Successfully", { type: "success" });

            navigate("/");
          })
          .catch((error) => {
            setisLoading(false);
            if (error?.response?.status == 422)
              toast(error.response.data.error, { type: "error" });
            else if (error?.response?.status == 500)
              toast(error.response.data.message, { type: "error" });
            else toast("Something went wrong", { type: "error" });
          });
      },

      onReset: () => {},
    });

  return (
    <div className="auto-container" style={{ marginTop: "230px" }}>
      <div
        // className="c-page-form-box"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="loc-block col-lg-6 col-md-12 col-sm-12">
          <div className="title-box centered" style={{ marginBottom: "40px" }}>
            <h2>Login</h2>
          </div>
          <div className="default-form reservation-form">
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                handleSubmit();
              }}
            >
              <div className="clearfix">
                <div className="form-group ">
                  <div className="field-inner">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email*"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{
                        borderColor: touched?.email && errors?.email && "red",
                      }}
                    />
                  </div>
                  {touched?.email && errors?.email && (
                    <p className="error-message">{errors.email}</p>
                  )}
                </div>
                <div className="form-group relative">
                  <div className="">
                    <input
                      type={isPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Password*"
                      style={{
                        borderColor:
                          touched?.password && errors?.password && "red",
                      }}
                    />
                    <span
                      className={`arrow-icon far  ${
                        isPassword ? "fa-eye" : "fa-eye-slash"
                      } `}
                      style={{
                        cursor: "pointer",
                        zIndex: "50",
                        position: "absolute",
                        top: "18px",
                        right: "16px",
                      }}
                      onClick={() => {
                        setisPassword(!isPassword);
                      }}
                    ></span>
                  </div>
                  {touched?.password && errors?.password && (
                    <p className="error-message">{errors.password}</p>
                  )}
                  <p className="more-link">
                    <Link to="/register">
                      {" "}
                      Dont't have an account? Create Here.
                    </Link>
                  </p>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Login</span>
                      <span className="text-two">Login</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
