import React from "react";
import Offers from "../Allmenus/FirstMenu/Offers";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Appetizers from "../Allmenus/Thrdmenu/Appetizers";
import Privateevent from "../Allmenus/Thrdmenu/Privateevent";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-2.jpg";
import History from "./History";

function Main() {
  return (
    <>
      <Bredcrumb
        subtitle="Order History"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
      <History />
    </>
  );
}

export default Main;
