import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { convertToSlug, decodeHTMLEntities } from "../../../utils/constant";

const Cart = () => {
  const [items, setItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [totalTaxAmount, settotalTaxAmount] = useState(0);
  const [addressDetails, setaddressDetails] = useState([]);
  const [total, setTotal] = useState(0);
  const [rate, setRate] = useState(0);
  const [popUpmodal, setpopUpmodal] = useState(false);
  const [proceedWithCartPopup, setproceedWithCartPopup] = useState(false);
  //   const { cart_qty, setCartQty } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let st = 0;
    let totalTax = 0;
    items.length > 0 &&
      items.map((i) => {
        st += i.quantity * i.item.sales_price;
        if (i?.item?.taxValue?.tax_product_amount) {
          totalTax += i?.quantity * i?.item?.taxValue?.tax_product_amount;
        }
      });

    if (st === 0) setTotal(0);
    else setTotal((st + rate + totalTax).toFixed(2));
  }, [rate, subtotal]);

  useEffect(() => {
    let st = 0;
    let totalTax = 0;

    items.map((i) => {
      st += i.quantity * i.item.sales_price;
      if (i?.item?.taxValue?.tax_product_amount) {
        totalTax += i?.quantity * i?.item?.taxValue?.tax_product_amount;
      }
    });

    setSubtotal(st.toFixed(2));
    settotalTaxAmount(totalTax?.toFixed(2));

    if (st == 0) setTotal(0);
    else setTotal((st + rate + totalTax).toFixed(2));
  }, [items]);

  return (
    <main className="main">
      {/* <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Cart
          </div>
        </div>
      </div> */}
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-12 mb-40">
            <h1 className="heading-2 mb-10 mt-5">Your Cart</h1>
            <div className="d-flex justify-content-between">
              <h6 className="">
                There are <span className="text-brand"> {"5"} </span> products
                in your cart
              </h6>
              <h6 className="text d-none">
                <a href="#" className="text-muted">
                  <i className="fi-rs-trash mr-5 " />
                  Clear Cart
                </a>
              </h6>
            </div>
            {total < 199 && (
              <div
                className="alert alert-danger mt-3 "
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "6px",
                  marginRight: "5px",
                }}
                role="alert"
              >
                <i className="fi-rs-cross-circle ml-3" />
                Minimum Order value $200 to proceed with your order!
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              className="table-responsive shopping-summery"
              style={{
                height: "670px",
                backgroundColor: "black",
              }}
            >
              <table className="table table-wishlist">
                <thead
                  style={{
                    top: "0",
                    position: "sticky",
                    zIndex: "1",
                  }}
                >
                  <tr className="main-heading">
                    {/* <th className="custome-checkbox start pl-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    id="exampleCheckbox11"
                    defaultValue=""
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheckbox11"
                  />
                </th> */}
                    <th
                      scope="col"
                      className="text-center"
                      colSpan={2}
                      style={{
                        color: "white",
                      }}
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        color: "white",
                      }}
                    >
                      {/* Stock Status */}
                    </th>
                    <th
                      scope="col"
                      style={{
                        color: "white",
                      }}
                    >
                      Unit Price
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        color: "white",
                      }}
                    >
                      Quantity
                    </th>
                    {/* <th
                      scope="col"
                      style={{
                        color: "white",
                      }}
                    >
                      Subtotal
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        color: "white",
                      }}
                    >
                      Tax
                    </th> */}
                    <th
                      scope="col"
                      className="text-center"
                      style={{
                        color: "white",
                      }}
                    >
                      Grand Total
                    </th>

                    <th
                      scope="col"
                      className="end"
                      style={{
                        color: "white",
                      }}
                    >
                      Remove
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {[0, 1].map((item, index) => {
                    return (
                      <>
                        <tr
                          // key={index}
                          className="d-lg-none d-md-none mobile-w-c pt-30 "
                        >
                          <div
                            className="row"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <div className="col-4">
                              <td className="image product-thumbnail pt-40">
                                <Link
                                  style={{ marginLeft: "5px" }}
                                  to={
                                    "/product/" +
                                    item?.item_id +
                                    "/" +
                                    convertToSlug(item?.item?.item_name) +
                                    "/" +
                                    true
                                  }
                                  title={item?.item?.item_name}
                                  state={{ isItem: true }}
                                >
                                  <img
                                    width={90}
                                    height={90}
                                    src={
                                      item?.image
                                        ? process.env.REACT_APP_BASE_URL +
                                          "/storage/products/" +
                                          item?.image
                                        : process.env.REACT_APP_BASE_URL +
                                          "/storage/products/no-image.png"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </td>
                            </div>
                            <div className="col-8 align-items-center justify-content-center d-flex">
                              <td
                                className="product-des product-name"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <h6 className="mb-5">
                                  <Link
                                    className="product-name mb-10 text-heading"
                                    to={
                                      "/product/" +
                                      item?.item_id +
                                      "/" +
                                      convertToSlug(item?.item?.item_name) +
                                      "/" +
                                      true
                                    }
                                    title={"titleName"}
                                    state={{ isItem: true }}
                                  >
                                    {"titleName"}
                                  </Link>
                                </h6>
                                <div className="product-rate-cover">
                                  <div className="product-rate d-inline-block">
                                    <div
                                      className="product-rating"
                                      style={{ width: "90%" }}
                                    ></div>
                                  </div>
                                  <span className="font-small ml-5 text-muted">
                                    {" "}
                                    (4.0)
                                  </span>
                                </div>
                              </td>
                            </div>
                            <div className="col-6">
                              <td
                                className="price"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <h6 className="text-body">
                                  ${item?.item?.sales_price?.toFixed(2)}
                                </h6>
                                <span className="user-id-font">
                                  {/* <span> {userId}</span> */}
                                </span>
                              </td>
                            </div>
                            <div className="col-6">
                              <td
                                className="price"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <span>Tax:-</span>
                                  {items[index]?.item?.taxValue
                                    ?.tax_product_amount ? (
                                    <p className="text-brand ">
                                      $
                                      {(
                                        items[index]?.item?.taxValue
                                          ?.tax_product_amount * item.quantity
                                      ).toFixed(2)}
                                    </p>
                                  ) : (
                                    <p className="text-brand">NA</p>
                                  )}
                                </div>
                              </td>
                            </div>
                            <div className="col-5">
                              <td
                                className="text-center detail-info"
                                data-title="Stock"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <div
                                  className="detail-extralink"
                                  style={{ marginRight: "-20px" }}
                                >
                                  <div className="detail-qty border radius">
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (item?.quantity > 1) {
                                          // decreaseQuantity(item.id, index);
                                        }
                                      }}
                                      className="qty-down"
                                    >
                                      <i className="fi-rs-angle-small-down" />
                                    </a>

                                    <input
                                      className="qty-val"
                                      value={item?.quantity}
                                      type="number"
                                      min={0}
                                      onChange={(e) => {
                                        if (
                                          typeof (
                                            Number(e?.target?.value) ===
                                            "number"
                                          ) ||
                                          e?.target?.value === ""
                                        ) {
                                          let countQty =
                                            e?.target?.value === ""
                                              ? 0
                                              : e?.target?.value;
                                          if (countQty === 0 || countQty > 0) {
                                            // modifiedItemQty(
                                            //   item?.id,
                                            //   index,
                                            //   countQty
                                            // );
                                          }
                                        }
                                      }}
                                    />
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e?.preventDefault();
                                        //   increaseQuantity(
                                        //     item.id,
                                        //     index,
                                        //     item.quantity,
                                        //     item.item?.stock,
                                        //     item.item?.purchase_limit
                                        //   );
                                      }}
                                      className="qty-up"
                                    >
                                      <i className="fi-rs-angle-small-up" />
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </div>
                            <div className="col-7">
                              <td
                                className="price"
                                data-title="Price"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <p className="text-brand">
                                  $
                                  {(
                                    item?.item?.sales_price * item?.quantity
                                  ).toFixed(2)}{" "}
                                </p>
                              </td>
                            </div>
                            <div className="col-8">
                              <td
                                className="price"
                                data-title="Grand Total"
                                style={
                                  {
                                    // backgroundColor: isDisable && "#A4A9A9",
                                  }
                                }
                              >
                                <h4 className="text-brand text-center">
                                  {/* ${totalAmount.toFixed(2)}{" "} */}
                                </h4>
                              </td>
                            </div>

                            <div className="col-4">
                              <td className="action delete-cart">
                                {/* {item.item.stock > 0 ? (
                                    <span className="stock-status in-stock mb-0">
                                      {" "}
                                      In Stock{" "}
                                    </span>
                                  ) : (
                                    <span className="stock-status out-stock mb-0">
                                      {" "}
                                      Stock Out{" "}
                                    </span>
                                  )} */}
                                <p
                                  // onClick={(e) => handleRemoveItem(e, item)}
                                  onClick={(e) => {
                                    //   setdeleteItem(item);
                                    //   setdeletePopup(true);
                                  }}
                                  className="text-body"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <i className="fi-rs-trash" />
                                </p>
                              </td>
                            </div>
                          </div>
                        </tr>
                        <tr
                          key={index}
                          className="pt-30 mobile-cart cart-table-lg"
                        >
                          {/* <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id={"cart-ckb-" + index}
                          defaultValue=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"cart-ckb-" + index}
                        />
                      </td> */}
                          <td
                            className="image product-thumbnail pt-40"
                            id={`disableIdd${index}`}
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <Link
                              style={{ marginLeft: "5px" }}
                              className="m-2"
                              to={
                                "/product/" +
                                item?.item_id +
                                "/" +
                                convertToSlug(item?.item?.item_name) +
                                "/" +
                                true
                              }
                              title={item?.item?.item_name}
                              state={{ isItem: true }}
                            >
                              <img
                                width={90}
                                height={90}
                                src={
                                  item?.image
                                    ? process.env.REACT_APP_BASE_URL +
                                      "/storage/products/" +
                                      item?.image
                                    : process.env.REACT_APP_BASE_URL +
                                      "/storage/products/no-image.png"
                                }
                                alt="#"
                              />
                            </Link>
                          </td>
                          <td
                            className="product-des product-name cart-product-name-wc"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <h6 className="mb-5">
                              <Link
                                className="product-name mb-10 text-heading"
                                to={
                                  "/product/" +
                                  item?.item_id +
                                  "/" +
                                  convertToSlug(item?.item?.item_name) +
                                  "/" +
                                  true
                                }
                                title={"titleName"}
                                state={{ isItem: true }}
                              >
                                {"titlename"}
                              </Link>
                            </h6>
                            <div className="product-rate-cover">
                              <div className="product-rate d-inline-block">
                                <div
                                  className="product-rating"
                                  style={{ width: "90%" }}
                                ></div>
                              </div>
                              <span className="font-small ml-5 text-muted">
                                {" "}
                                (4.0)
                              </span>
                            </div>
                          </td>
                          <td
                            className="text-center pt-5"
                            // data-title="Stock"
                          >
                            {/* {item.item.stock > 0 ? (
                                <span className="stock-status in-stock mb-0">
                                  {" "}
                                  In Stock{" "}
                                </span>
                              ) : (
                                <span className="stock-status out-stock mb-0">
                                  {" "}
                                  Stock Out{" "}
                                </span>
                              )} */}
                          </td>
                          <td
                            className="price"
                            data-title="Price"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <h6 className="text-body">
                              {/* ${item.item?.sales_price?.toFixed(2)} */}
                              sales_price
                            </h6>
                            <span className="user-id-font">
                              {/* <span> {userId}</span> */}
                            </span>
                          </td>
                          <td
                            className="text-center detail-info"
                            data-title="Stock"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <div className="detail-extralink mr-15">
                              <div className="detail-qty border radius">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (item?.quantity > 1) {
                                      //   decreaseQuantity(item.id, index);
                                    }
                                  }}
                                  className="qty-down"
                                >
                                  <i className="fi-rs-angle-small-down" />
                                </a>
                                {/* <span className="qty-val">{item.quantity}</span> */}
                                <input
                                  className="qty-val"
                                  type="number"
                                  min={0}
                                  style={{
                                    border: "none",
                                    maxHeight: "28px",
                                  }}
                                  value={item?.quantity}
                                  onChange={(e) => {
                                    if (
                                      typeof (
                                        Number(e?.target?.value) === "number"
                                      ) ||
                                      e?.target?.value === ""
                                    ) {
                                      let countQty =
                                        e?.target?.value === ""
                                          ? 0
                                          : e?.target?.value;
                                      if (countQty === 0 || countQty > 0) {
                                        // modifiedItemQty(
                                        //   item?.id,
                                        //   index,
                                        //   countQty
                                        // );
                                      }
                                    }
                                  }}
                                />

                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // increaseQuantity(
                                    //   item.id,
                                    //   index,
                                    //   item.quantity,
                                    //   item.item?.stock,
                                    //   item.item?.purchase_limit
                                    // );
                                  }}
                                  className="qty-up"
                                >
                                  <i className="fi-rs-angle-small-up" />
                                </a>
                              </div>
                            </div>
                          </td>
                          <td
                            className="price"
                            data-title="Price"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <b className="text-brand">
                              $
                              {(
                                item?.item?.sales_price * item?.quantity
                              ).toFixed(2)}{" "}
                            </b>
                          </td>
                          <td
                            className="price"
                            data-title="Price"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "3px",
                                justifyContent:
                                  !items[index]?.item?.taxValue && "center",
                              }}
                            >
                              {items[index]?.item?.taxValue
                                ?.tax_product_amount ? (
                                <p className="text-brand">
                                  $
                                  {(
                                    items[index]?.item?.taxValue
                                      ?.tax_product_amount * item?.quantity
                                  ).toFixed(2)}
                                </p>
                              ) : (
                                <p className="text-brand">NA</p>
                              )}
                            </div>
                          </td>
                          <td
                            className="price"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <h4 className="text-brand text-center">
                              {/* ${totalAmount?.toFixed(2)} */}
                              totalAmount
                            </h4>
                          </td>
                          <td
                            className="action text-center"
                            style={
                              {
                                // backgroundColor: isDisable && "#A4A9A9",
                              }
                            }
                          >
                            <p
                              href=""
                              // onClick={(e) => handleRemoveItem(e, item)}
                              onClick={(e) => {
                                //   setdeleteItem(item);
                                //   setdeletePopup(true);
                              }}
                              className="text-body"
                            >
                              <i className="fi-rs-trash" />
                            </p>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="divider-2 mb-30" />

            <h6
              className="text-brand mb-2 curser-hover pe-auto"
              onClick={() => {
                // setisAddressPopup(true);
              }}
            >
              <a
                href=""
                onClick={(e) => {
                  e?.preventDefault();
                }}
              >
                {/* Change Address? */}
              </a>
            </h6>
            <div
              className="cart-action  d-flex gap-3 mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <Link
                className="theme-btn  btn-style-one clearfix "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="btn-wrap">
                  <span className="text-one"> Empty Cart</span>
                  <span className="text-two"> Empty Cart</span>
                </span>
              </Link>

              <Link
                className="theme-btn  btn-style-one clearfix "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="btn-wrap">
                  <span className="text-one"> Explore Menu</span>
                  <span className="text-two"> Explore Menu</span>
                </span>
              </Link>
            </div>
            <div className="row mt-50 d-none">
              <div className="col-lg-7">
                <div className="calculate-shiping p-40 border-radius-15 border">
                  <h4 className="mb-10">Calculate Shipping</h4>
                  <p className="mb-30">
                    <span className="font-lg text-muted">Flat rate:</span>
                    <strong className="text-brand">5%</strong>
                  </p>
                  <form className="field_form shipping_calculator">
                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <div className="custom_select">
                          <select
                            className="form-control select-active w-100 select2-hidden-accessible"
                            data-select2-id={7}
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            <option value="" data-select2-id={9}>
                              United Kingdom{" "}
                            </option>
                          </select>
                          <span
                            className="select2 select2-container select2-container--default"
                            dir="ltr"
                            data-select2-id={8}
                            style={{ width: "520.094px" }}
                          >
                            <span className="selection">
                              <span
                                className="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabIndex={0}
                                aria-labelledby="select2-n4i4-container"
                              >
                                <span
                                  className="select2-selection__rendered"
                                  id="select2-n4i4-container"
                                  role="textbox"
                                  aria-readonly="true"
                                  title="United Kingdom"
                                >
                                  United Kingdom
                                </span>
                                <span
                                  className="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation" />
                                </span>
                              </span>
                            </span>
                            <span
                              className="dropdown-wrapper"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row row">
                      <div className="form-group col-lg-6">
                        <input
                          required="required"
                          placeholder="State / Country"
                          name="name"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          required="required"
                          placeholder="ZIP"
                          name="name"
                          type="text"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="p-40">
                  <h4 className="mb-10">Apply Coupon</h4>
                  <p className="mb-30">
                    <span className="font-lg text-muted">
                      Using A Promo Code?
                    </span>
                  </p>
                  <form action="#">
                    <div className="d-flex justify-content-between">
                      <input
                        className="font-medium mr-15 coupon"
                        name="Coupon"
                        placeholder="Enter Your Coupon"
                      />
                      <button className="btn">
                        <i className="fi-rs-label mr-10" />
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* {addressDetails.addresses?.length && (
            <ConfirmationPopupModal
              title={
                "Your Address is selected as default address ,if you want to change it you can change"
              }
              // content={`Your Address is selected as default address ,if you want to change it you can change`}
              show={isAddressPopup}
              addressData={addressDetails}
              setShow={setisAddressPopup}
              isAddress={true}
              addressId={addressId}
              addre
              setaddressId={setaddressId}
              onClose={onCloseAddressPopup}
              confirmClicked={(e) => {
                updateAddressValue();
              }}
              addressNum={addressNum}
              setaddressNum={setaddressNum}
            />
          )} */}
        </div>
        <div className="cart m-4">
          <div className="col-lg-4">
            <div
              className="border p-md-4 cart-totals ml-12"
              style={{ backgroundColor: "black" }}
            >
              <div className="table-responsive">
                <table className="table no-border">
                  <tbody>
                    <tr>
                      <td className="cart_total_label">
                        <h6 className="">Subtotal</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">${subtotal}</h4>
                      </td>
                    </tr>

                    <tr>
                      <td className="cart_total_label">
                        <h6 className="">Tax</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">
                          ${totalTaxAmount || 0}
                        </h4>
                      </td>
                    </tr>
                    {/* <tr>
                  <td scope="col" colSpan={2}>
                    <div className="divider-2 mt-10 mb-10" />
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="">Shipping</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">Free </h5>
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="">Estimate for</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">United Kingdom </h5>
                  </td>
                </tr> */}
                    <tr>
                      <td scope="col" colSpan={2}>
                        <div className="divider-2 mt-10 mb-10" />
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">
                        <h6 className="">Grand total</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">${total}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Link
                className="theme-btn  btn-style-one clearfix "
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className="btn-wrap">
                  <span className="text-one">
                    {" "}
                    <i className="icon far fa-shopping-cart"></i> Proceed
                  </span>
                  <span className="text-two">
                    {" "}
                    <i className="icon far fa-shopping-cart"></i> Proceed
                  </span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Cart;
